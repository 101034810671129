<template>
  <div class="post-detail">
    <div class="post-header">
      <h2>{{ post.title }}</h2>
      <button v-if="userLevel === 3" @click="confirmDelete" class="delete-button">Delete Post</button>
    </div>
    <div class="post-info">
      <span>Author: {{ post.author }}</span>
      <span>Time: {{ post.createdAt }}</span>
      <span>Topic: {{ post.tags}}</span>
    </div>
    <div class="post-content">
      <p>{{ post.content }}</p>
    </div>
    <div class="comments">
      <h3>Comments</h3>
      <div v-for="(comment, index) in comments" :key="index" class="comment">
        <div class="comment-header">
          <!-- <img :src="comment.avatar" alt="avatar" class="avatar"/> -->
          <div class="comment-author">
            <strong>{{ comment.author }}</strong>
            <span class="comment-time">{{ comment.createdAt }}</span>
          </div>
          <button v-if="userLevel === 3" @click="confirmDeleteComment(index)" class="delete-comment-button">delete</button>
        </div>
        <p class="comment-content">{{ comment.content }}</p>
        <div class="comment-footer">
          <span class="comment-likes">▲ {{ comment.likes }}</span>
          <button @click="toggleReplies(index)">
            {{ showReplies[index] ? 'Hide' : 'Show' }} replies ({{ comment.replies.length }})
          </button>
        </div>
        <div v-if="showReplies[index]" class="replies">
          <div v-for="(reply, replyIndex) in paginatedReplies(index)" :key="replyIndex" class="reply">
            <p><strong>{{ reply.author }}:</strong> {{ reply.content }}</p>
            <button v-if="userLevel === 3" @click="confirmDeleteReply(index, replyIndex)" class="delete-comment-button">delete</button>
          </div>
          <div class="pagination">
            <button @click="prevPage(index)" :disabled="currentPage[index] === 1">Previous</button>
            <span>Page {{ currentPage[index] }} of {{ totalPages(index) }}</span>
            <button @click="nextPage(index)" :disabled="currentPage[index] === totalPages(index)">Next</button>
          </div>
          <div class="reply-input">
            <input v-model="newReply[index]" placeholder="Write a reply..." />
            <button @click="submitReply(index)">Reply</button>
          </div>
        </div>
      </div>
    </div>
    <div class="post-reply">
      <h3>Reply to Post</h3>
      <div class="reply-input">
        <input v-model="postReply" placeholder="Write a reply to the post..." />
        <button @click="submitPostReply">Reply</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { post } from "@/api/index";

export default {
  name: 'PostDetail',
  data() {
    return {
      post: {},
      comments: [],
      showReplies: [],
      currentPage: [],
      newReply: [],
      postReply: '',
      repliesPerPage: 10,
      userLevel: 0,
      userId: 0,
      userName:'',
    };
  },
  mounted() {
    this.fetchPostDetails();
    this.fetchUserInfo(); 
  },
  methods: {
    async fetchPostDetails() {
      const post = JSON.parse(sessionStorage.getItem('currentPost'));
      if (post) {
        this.post = post;
        await this.fetchComments(post.id);
      } else {
        this.post = {
          title: 'Post not found',
          author: '',
          createdAt: '',
          tags: '',
          content: 'The post you are looking for does not exist.'
        };
      }
    },
    async fetchComments(postId) {
      try {
        const response = await post('/forum/comment/get-by-post', {
          postId: postId,
          pageNum: 1,
          pageSize: 10,
          sort: 'created_at',
          isAsc: 'false',
        });

        if (response.res === 1) {
          const commentMap = {};

          response.data.forEach(comment => {
            if (comment.parentCommentId === 0) {
              commentMap[comment.id] = {
                author: comment.userName,
                content: comment.content,
                createdAt: comment.createdAt,
                likes: comment.likes,
                replies: [],
                showReplies: false,
                currentPage: 1,
                newReply: '',
                commentId: comment.id,
              };
            } else {
              if (commentMap[comment.parentCommentId]) {
                commentMap[comment.parentCommentId].replies.push({
                  author: comment.userName,
                  content: comment.content,
                  createdAt: comment.createdAt,
                  likes: comment.likes,
                  commentId: comment.id,
                });
              }
            }
          });

          this.comments = Object.values(commentMap);
          this.showReplies = this.comments.map(() => false);
          this.currentPage = this.comments.map(() => 1);
          this.newReply = this.comments.map(() => '');
        } else {
          ElMessage.error('Failed to fetch comments');
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
        ElMessage.error('Error fetching comments');
      }
    },
    toggleReplies(index) {
      this.showReplies[index] = !this.showReplies[index];
    },
    paginatedReplies(index) {
      const start = (this.currentPage[index] - 1) * this.repliesPerPage;
      const end = start + this.repliesPerPage;
      return this.comments[index].replies.slice(start, end);
    },
    totalPages(index) {
      return Math.ceil(this.comments[index].replies.length / this.repliesPerPage);
    },
    prevPage(index) {
      if (this.currentPage[index] > 1) {
        this.currentPage[index]--;
      }
    },
    nextPage(index) {
      if (this.currentPage[index] < this.totalPages(index)) {
        this.currentPage[index]++;
      }
    },
    async submitReply(index) {
      if (this.newReply[index].trim()) {
        try {
          const response = await post('/forum/comment/reply', {
            userId: this.userId,
            postId: this.post.id,
            parentId: this.comments[index].commentId,
            content: this.newReply[index]
          });

          if (response.res === 1) {
            this.comments[index].replies.push({
              author: this.userName,
              content: this.newReply[index],
              createdAt: new Date().toLocaleString(),
              likes: 0,
              commentId: response.data 
            });
            this.newReply[index] = '';
            this.currentPage[index] = this.totalPages(index);
            ElMessage.success('Reply submitted successfully');
          } else {
            ElMessage.error('Failed to submit reply');
          }
        } catch (error) {
          console.error('Error submitting reply:', error);
          ElMessage.error('Error submitting reply');
        }
      }
    },
    async submitPostReply() {
      if (this.postReply.trim()) {
        try {
          const response = await post('/forum/comment/create', {
            userId: this.userId,
            postId: this.post.id,
            parentCommentId: 0,
            content: this.postReply
          });

          // (response.res === 1) {
            this.comments.push({
              author: this.userName,
              createdAt: new Date().toLocaleString(),
              content: this.postReply,
              likes: 0,
              replies: [],
              showReplies: false,
              currentPage: 1,
              newReply: '', 
              commentId: response.data
            });
            this.postReply = '';
            ElMessage.success('Comment submitted successfully');
           //} 
            //else {
            //ElMessage.error('Failed to submit comment');
           //}
        } catch (error) {
          console.error('Error submitting comment:', error);
          ElMessage.error('Error submitting comment');
        }
      }
    },
    async fetchUserInfo() {
      try {
        if (sessionStorage.getItem("token")) {
          const response = await post('/user/logged');
          if (response.res === 1) {
            this.userLevel = response.user.admin;
            this.userId = response.user.uid;
            this.userName=response.user.uname;
          } else {
            ElMessage.error('Failed to fetch user info');
          }
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        ElMessage.error('Error fetching user info');
      }
    },
    confirmDelete() {
      ElMessageBox.confirm(
        'Are you sure you want to delete this post?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        }
      ).then(() => {
        this.deletePost();
      }).catch(() => {
        ElMessage.info('Delete canceled');
      });
    },
    async deletePost() {
      try {
        const response = await post('/forum/post/logic-delete', {
          postId: this.post.id,
        });

        if (response.res === 1) {
          ElMessage.success('Post deleted successfully');
          this.$router.push('/forum');
        } else {
          ElMessage.error('Failed to delete post');
        }
      } catch (error) {
        console.error('Error deleting post:', error);
        ElMessage.error('Error deleting post');
      }
    },
    confirmDeleteComment(index) {
      ElMessageBox.confirm(
        'Are you sure you want to delete this comment?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        }
      ).then(() => {
        this.deleteComment(index);
      }).catch(() => {
        ElMessage.info('Delete canceled');
      });
    },
    async deleteComment(index) {
      try {
        const response = await post('/forum/comment/logic-delete', {
          commentId: this.comments[index].commentId,
        });

        if (response.res === 1) {
          ElMessage.success('Comment deleted successfully');
          this.comments.splice(index, 1);
        } else {
          ElMessage.error('Failed to delete comment');
        }
      } catch (error) {
        console.error('Error deleting comment:', error);
        ElMessage.error('Error deleting comment');
      }
    },
    confirmDeleteReply(commentIndex, replyIndex) {
      ElMessageBox.confirm(
        'Are you sure you want to delete this reply?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        }
      ).then(() => {
        this.deleteReply(commentIndex, replyIndex);
      }).catch(() => {
        ElMessage.info('Delete canceled');
      });
    },
    async deleteReply(commentIndex, replyIndex) {
      try {
        const response = await post('/forum/comment/logic-delete', {
          commentId: this.comments[commentIndex].replies[replyIndex].commentId,
        });

        if (response.res === 1) {
          ElMessage.success('Reply deleted successfully');
          this.comments[commentIndex].replies.splice(replyIndex, 1); 
        } else {
          ElMessage.error('Failed to delete reply');
        }
      } catch (error) {
        console.error('Error deleting reply:', error);
        ElMessage.error('Error deleting reply');
      }
    }
  }
};
</script>

<style scoped>
.post-detail {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-detail h2 {
  margin-top: 0;
  color: #111880;
  font-family: "Poppins-ExtraBold";
}
.delete-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 5px;
  cursor: pointer;
}
.delete-button:hover {
  background-color: #ff7875;
}
.post-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
}
.post-content p {
  font-size: 16px;
  color: #666;
  font-family: "Poppins-Regular";
  white-space: pre-wrap; /* 保持换行格式 */
}
.comments {
  margin-top: 30px;
}
.comments h3 {
  margin-bottom: 20px;
  font-family: "Poppins-ExtraBold";
}
.comment {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}
.comment-header {
  display: flex;
  align-items: center;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.comment-author {
  display: flex;
  flex-direction: column;
}
.comment-time {
  font-size: 12px;
  color: #999;
}
.comment-content {
  margin: 10px 0;
  font-size: 14px;
  color: #333;
}
.comment-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #999;
}
.comment-likes {
  color: #007bff;
}
.replies {
  margin-top: 10px;
  padding-left: 20px;
  border-left: 2px solid #ccc;
}
.reply {
  margin-bottom: 10px;
}
.reply-input {
  display: flex;
  margin-top: 10px;
}
.reply-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.reply-input button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.reply-input button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.post-reply {
  margin-top: 30px;
}
.post-reply h3 {
  margin-bottom: 10px;
  font-family: "Poppins-ExtraBold";
}
.delete-comment-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 10px;
  margin-left: auto;
  text-transform: uppercase;
}
</style>
